import { useNavigate } from '@reach/router'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import { trackCancellationSave } from '~/analytics/events'
import { PanelCancelOption } from '~/components/CancelFlowModals/PanelCancelLayout'
import { TEST_ID } from '~/constants/cypress'
import { ReachRouterHistoryContext } from '~/context/reachRouterHistory'
import { mutateCancelSubscription } from '~/hooks/mutateCancelSubscription'
import { captureNetworkError } from '~/analytics/errors'
import { LoadingPanel } from '../../LoadingPanel/LoadingPanel'
import useShowcaseProducts from './hooks/useShowcaseProducts'
import PanelAreYouSureUI from './PanelAreYouSure.ui'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'

const PanelAreYouSure: React.FC<CancelPanelProps> = ({
  handleModalClose,
  panel,
}) => {
  const navigate = useNavigate()
  const showToast = React.useContext(ToastContext)
  const { getHistoryEntry } = React.useContext(ReachRouterHistoryContext)
  const [cancelSubscription, { status }] = mutateCancelSubscription()
  const showCaseProducts = useShowcaseProducts()

  const ModalOptions = React.useMemo<PanelCancelOption[]>(
    () => [
      {
        'data-cy': TEST_ID.CANCEL_FLOW_SAVE,
        text: 'Nevermind, stay subscribed',
        onClick: () => {
          handleModalClose()
          showToast('success', {
            children: "Great choice! We're happy you decided to stick around.",
          })
          trackCancellationSave(
            CANCEL_SAVE_ACTIONS.ARE_YOU_SURE,
            getHistoryEntry(1).pathname,
            getHistoryEntry(2).state.text
          )
        },
      },
      {
        'data-cy': TEST_ID.CANCEL_FLOW_OPTION,
        text: 'Delay my next box',
        urlDestination: '/delay',
        status: status === 'loading' ? 'disabled' : 'idle',
      },
      {
        'data-cy': TEST_ID.CANCEL_FLOW_STOP_SUBSCRIPTION,
        text: 'Stop my subscription and lose my perks',
        onClick: () => {
          cancelSubscription(undefined, {
            onSuccess() {
              navigate('/confirmed-stopped')
            },
            onError(e) {
              captureNetworkError(e, {
                pageBeforeAreYouSure: getHistoryEntry(-2).pathname,
              })
              showToast('error', {
                children:
                  'There was an issue canceling your subscription. Please try again or contact customer service to continue.',
              })
              handleModalClose()
            },
          })
        },
      },
    ],
    [
      cancelSubscription,
      getHistoryEntry,
      handleModalClose,
      navigate,
      showToast,
      status,
    ]
  )

  return status === 'loading' ? (
    <LoadingPanel />
  ) : (
    <PanelAreYouSureUI
      ModalOptions={ModalOptions}
      navigate={navigate}
      panel={panel}
      showCaseProducts={showCaseProducts}
    />
  )
}

export default PanelAreYouSure
