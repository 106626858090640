import { Flex, Grid } from '@chakra-ui/core'
import { rem } from 'design'
import { ModalHeader } from 'design/components/Modal/Modal'
import { Body, BodySmaller } from 'design/components/Typography/Typography'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import PanelLayout, {
  ModalItemSmall,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import * as Styled from './PanelAreYouSure.styles'
import type * as TYPES from './PanelAreYouSure.types'

const PanelAreYouSureUI = ({
  panel,
  ModalOptions,
  showCaseProducts,
  navigate,
}: TYPES.PanelAreYouSureUIProps) => {
  return (
    <PanelLayout>
      <ModalHeader pb={rem(8)} variant="bare">
        {panel.headline}
      </ModalHeader>
      <Body>{panel.subhead}</Body>
      <Styled.Grid>
        {showCaseProducts.map((product) => (
          <Flex as="li" direction="column" key={product.key}>
            <GatsbyImage fluid={product.image.childImageSharp.fluid} />
            <BodySmaller
              fontWeight="bold"
              marginTop={rem(8)}
              minHeight={{ base: rem(32), tablet: 'unset' }}
              textAlign="center"
            >
              {product.name}
            </BodySmaller>
          </Flex>
        ))}
      </Styled.Grid>
      <Grid gap={rem(16)} gridTemplateColumns="1 fr">
        {ModalOptions.map((cancelOption) => {
          if ('urlDestination' in cancelOption) {
            const { text, urlDestination, ...props } = cancelOption
            return (
              <ModalItemSmall
                key={text}
                onClick={() => navigate(urlDestination)}
                {...props}
              >
                {text}
              </ModalItemSmall>
            )
          } else if ('onClick' in cancelOption) {
            const { text, onClick, ...props } = cancelOption
            return (
              <ModalItemSmall key={text} onClick={onClick} {...props}>
                {text}
              </ModalItemSmall>
            )
          }
          const typeGuard: never = cancelOption
          return typeGuard
        })}
      </Grid>
    </PanelLayout>
  )
}

export default PanelAreYouSureUI
