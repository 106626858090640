import { useStaticQuery, graphql } from 'gatsby'
import * as TYPES from '../PanelAreYouSure.types'

// This hook currently returns static data this is formatted
// for the presentational component. This could be a great point
// for personalization in future iterations, showcasing products
// in the cancel flow that best suit a members past purchase history

const useShowcaseProducts: TYPES.ShowcaseProductsHook = () => {
  const productNameMap: Record<TYPES.Products, string> = {
    potRoast: 'Pot Roast',
    boneInTomahawkSteak: 'Bone-In Tomahawk Steak',
    meatballs: 'Pre-Cooked Beef & Pork Meatballs',
  }

  const imageData = useStaticQuery(graphql`
    query {
      potRoast: file(relativePath: { eq: "pot_roast.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      boneInTomahawkSteak: file(
        relativePath: { eq: "bone-In_tomahawk_steak.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      meatballs: file(relativePath: { eq: "meatballs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return Object.keys(productNameMap).map((key: TYPES.Products) => {
    return {
      key,
      image: imageData[key],
      name: productNameMap[key],
    }
  })
}

export default useShowcaseProducts
