import { styled, rem } from 'design'

export const Grid = styled.ul`
  display: grid;
  grid-column-gap: ${rem(16)};
  grid-row-gap: ${rem(16)};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin: ${rem(16)} auto;
  ${(p) => p.theme.media.tablet} {
    // TODO:
    // this is carry over from props, fit-content doe not have great support
    // consider for refactor post code cleanup.
    max-width: fit-content;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }
  ${(p) => p.theme.media.desktop} {
    max-width: unset;
  }
`
